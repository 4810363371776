<template>
  <v-form
    :lazy-validation="false"
    @submit.prevent="handleSubmit"
    v-model="valid"
    ref="form"
  >
    <v-card>
      <v-toolbar color="primary" dark flat>
        <v-toolbar-title>{{ $t("alarm.create") }}</v-toolbar-title>
        <div class="flex-grow-1"></div>
      </v-toolbar>

      <v-card-text>
        <v-text-field
          :label="$t('alarm.fields.name')"
          name="name"
          prepend-icon="mdi-domain"
          type="text"
          v-model="name"
          :rules="nameRules"
        ></v-text-field>
      </v-card-text>

      <v-card-text>
        <v-text-field
          :label="$t('alarm.fields.message')"
          name="message"
          prepend-icon="mdi-message"
          type="text"
          v-model="message"
          :rules="messageRules"
        ></v-text-field>
      </v-card-text>

      <v-card-text>
        <v-autocomplete
          autocomplete="off"
          :label="$t('alarm.fields.activeDays')"
          v-model="activeDays"
          :items="days"
          item-text="name"
          item-value="value"
          prepend-icon="mdi-calendar-today"
          :rules="autocompleteRules"
          required
          multiple
        ></v-autocomplete>
      </v-card-text>

      <v-card-text>
        <v-autocomplete
          autocomplete="off"
          :label="$t('alarm.fields.alarmContacts')"
          v-model="alarmContacts"
          :items="companyUsers"
          :rules="autocompleteRules"
          required
          item-text="firstname"
          item-value="userId"
          multiple
          prepend-icon="mdi-account-box"
        >
          <template v-slot:item="data">
            <template v-if="isNotObject(data.item)">
              <v-list-item-content v-text="data.item"></v-list-item-content>
            </template>
            <template v-else>
              <v-list-item-content>
                <v-list-item-title
                  v-html="data.item.firstname"
                ></v-list-item-title>
                <v-list-item-subtitle
                  v-html="data.item.lastname"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </template>
        </v-autocomplete>
      </v-card-text>

      <v-card-text>
        <v-switch
          v-model="advanced"
          :label="$t('alarm.fields.advanced')"
        ></v-switch>
      </v-card-text>

      <v-card-text v-if="!advanced">
        <v-autocomplete
          autocomplete="off"
          :loading="tagStatus.loading"
          :label="$t('alarm.fields.tag')"
          v-model="tag"
          :items="tags"
          :rules="autocompleteRules"
          required
          item-text="name"
          item-value="deveui"
          prepend-icon="mdi-cellphone-link"
        ></v-autocomplete>
      </v-card-text>

      <v-card-text v-if="!advanced">
        <v-autocomplete
          autocomplete="off"
          :disabled="tagStatus.loading && tag === ''"
          :loading="tagDataStatus.loading"
          :label="$t('alarm.fields.tagDataKey')"
          v-model="tagDataKey"
          :items="keys"
          :rules="autocompleteRules"
          required
          item-text="label"
          item-value="key"
          prepend-icon="mdi-key"
        ></v-autocomplete>
      </v-card-text>

      <v-card-text v-if="!advanced">
        <v-autocomplete
          autocomplete="off"
          :label="$t('alarm.fields.signalType')"
          v-model="signalType"
          :items="signalTypes"
          required
          item-text="name"
          item-value="value"
          prepend-icon="mdi-wave"
        ></v-autocomplete>
      </v-card-text>

      <div v-if="signalType == 0 && !advanced">
        <v-card-text>
          <v-container fluid>
            <v-row justify="space-between">
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  :label="$t('alarm.fields.limitLow')"
                  type="number"
                  v-model="limitLow"
                  single-line
                  prepend-icon="mdi-priority-low"
                />
              </v-col>

              <v-spacer></v-spacer>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  :label="$t('alarm.fields.limitHigh')"
                  type="number"
                  v-model="limitHigh"
                  single-line
                  prepend-icon="mdi-priority-high"
                  v-if="!advanced"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </div>

      <v-card-text v-if="signalType == 1 && !advanced">
        <v-autocomplete
          autocomplete="off"
          :label="$t('alarm.fields.digitalTrigger')"
          v-model="digitalType"
          :items="digitalTypes"
          required
          item-text="name"
          item-value="value"
          prepend-icon="mdi-electric-switch"
        ></v-autocomplete>
      </v-card-text>

      <v-card-text>
        <v-subheader>{{ $t("alarm.fields.alarmDelay") }}</v-subheader>
        <v-slider
          v-model="alarmDelay"
          class="align-center"
          :max="delayMax"
          :min="delayMin"
          hide-details
        >
          <template v-slot:append>
            <v-text-field
              v-model="alarmDelay"
              class="mt-0 pt-0"
              hide-details
              single-line
              type="number"
              style="width: 60px"
            ></v-text-field>
          </template>
        </v-slider>
      </v-card-text>

      <v-card-text>
        <v-container fluid>
          <v-row align="center" justify="space-around">
            <v-col cols="12" sm="10" md="10">
              <v-select
                v-model="priority"
                :items="priorityItems"
                :label="$t('alarm.fields.priority')"
                item-text="name"
                item-value="value"
              >
                <template v-slot:item="data">
                  <template v-if="isNotObject(data.item)">
                    <v-list-item-content
                      v-text="data.item"
                    ></v-list-item-content>
                  </template>
                  <template v-else>
                    <v-list-item-content>
                      <v-list-item-title
                        v-html="data.item.name"
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        v-html="data.item.desc"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" sm="2" md="2">
              <v-switch
                v-model="active"
                :label="$t('alarm.fields.active')"
              ></v-switch>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-text>
        <condition-tree v-if="advanced" v-model="advancedAlarms" />
      </v-card-text>

      <v-card-actions>
        <v-btn color="primary" type="submit" :disabeld="!valid" x-large>{{
          $t("common.add")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { mapActions, mapState } from "vuex";
import i18n from "@/plugins/i18n";
import CsharpEnum from "@/_helpers/CsharpEnum";
import ConditionTree from "../conditionTree/ConditionTree.vue";

export default {
  name: "AlarmCreate",
  data() {
    return {
      submitted: false,
      valid: false,
      max: 80,
      min: -50,
      delayMax: 120,
      delayMin: 0,
      advanced: true,

      name: "",
      priority: CsharpEnum.Priority.C,
      alarmDelay: 10,
      active: true,
      tagDataKey: "",
      tag: "",
      message: "",
      limitLow: null,
      limitHigh: null,
      contactType: CsharpEnum.ContactType.BOTH,
      signalType: 0,
      digitalType: 0,
      alarmContacts: [],
      activeDays: [1, 2, 3, 4, 5],
      advancedAlarms: [],

      days: [
        {
          name: i18n.t("common.days.monday"),
          value: CsharpEnum.DaysOfWeek.monday,
        },
        {
          name: i18n.t("common.days.tuesday"),
          value: CsharpEnum.DaysOfWeek.tuesday,
        },
        {
          name: i18n.t("common.days.wednesday"),
          value: CsharpEnum.DaysOfWeek.wednesday,
        },
        {
          name: i18n.t("common.days.thursday"),
          value: CsharpEnum.DaysOfWeek.thursday,
        },
        {
          name: i18n.t("common.days.friday"),
          value: CsharpEnum.DaysOfWeek.friday,
        },
        {
          name: i18n.t("common.days.saturday"),
          value: CsharpEnum.DaysOfWeek.saturday,
        },
        {
          name: i18n.t("common.days.sunday"),
          value: CsharpEnum.DaysOfWeek.sunday,
        },
      ],

      priorityItems: [
        {
          name: "A",
          value: CsharpEnum.Priority.A,
          desc: i18n.t("alarm.priority.desc.a"),
        },
        {
          name: "B",
          value: CsharpEnum.Priority.B,
          desc: i18n.t("alarm.priority.desc.b"),
        },
        {
          name: "C",
          value: CsharpEnum.Priority.C,
          desc: i18n.t("alarm.priority.desc.c"),
        },
      ],

      signalTypes: [
        {
          name: "Analog",
          value: 0,
        },
        {
          name: "Digital",
          value: 1,
        },
      ],

      digitalTypes: [
        {
          name: i18n.t("alarm.fields.triggerHigh"),
          value: 0,
        },
        {
          name: i18n.t("alarm.fields.triggerLow"),
          value: 1,
        },
      ],

      nameRules: [
        (v) => !!v || this.$t("alarm.validation.nameRequired"),
        (v) => (v && v.length <= 255) || this.$t("alarm.validation.max"),
      ],

      messageRules: [
        (v) => !!v || this.$t("alarm.validation.nameRequired"),
        (v) => (v && v.length <= 255) || this.$t("alarm.validation.max"),
      ],

      autocompleteRules: [
        (v) => !!v || this.$t("alarm.validation.nameRequired"),
      ],
    };
  },

  computed: {
    ...mapState("users", [
      "status",
      "currentUser",
      "userCompany",
      "hasCompany",
    ]),
    ...mapState("tag", { tagStatus: "status", tags: "tags" }),
    ...mapState("tagData", { tagDataStatus: "status", keys: "keys" }),
    ...mapState("companies", {
      companyUsers: "companyUsers",
      companyStatus: "status",
    }),
  },

  methods: {
    ...mapActions("tag", ["getTags", "getKeysForTag"]),
    ...mapActions("companies", ["getCompanyUsers"]),
    ...mapActions("alarms", ["createAlarm"]),

    async handleSubmit() {
      if (!this.$refs.form.validate()) {
        this.valid = false;
        return;
      }

      this.submitted = true;

      const {
        name,
        priority,
        alarmDelay,
        active,
        tagDataKey,
        tag,
        message,
        alarmContacts,
        activeDays,
        limitLow,
        limitHigh,
      } = this;

      var payload = {
        name: name,
        message: message,
        priority: priority,
        tagDataKey: tagDataKey,
        limitLow: limitLow,
        limitHigh: limitHigh,
        contacts: alarmContacts,
        active: active,
        tagDeveui: tag,
        alarmDelay: alarmDelay,
        activeDays: [],
        alarmConditions: this.advancedAlarms,
        advanced: this.advanced,
      };

      if (this.signalType == 1 && this.digitalType == 0) {
        payload.limitHigh = 0;
        payload.limitLow = null;
      } else if (this.signalType == 1 && this.digitalType == 1) {
        payload.limitHigh = null;
        payload.limitLow = 1;
      }

      activeDays.forEach((day) => {
        payload.activeDays.push({ day: day });
      });

      if (this.valid) await this.createAlarm({ alarmPayload: payload });

      this.$router.push("/alarm");
    },

    isNotObject(o) {
      return typeof o !== "object";
    },
  },

  created() {
    this.getTags();
    this.getCompanyUsers();
  },

  watch: {
    tag(val) {
      this.getKeysForTag({ tagId: val });
    },
  },

  components: {
    ConditionTree,
  },
};
</script>

<style>
</style>